<template>
  <div class="cashierReceipt content_body">    
    <el-form ref="form" :model="cashierReceipt" label-width="100px">
      <div class="tip marbm_10 font_14 color_333" style="margin-top:0">小票信息</div>
      <el-form-item label="订单信息:" >
        <el-checkbox v-model="cashierReceipt.NameEncrypt">客户姓名加密</el-checkbox>
        <el-checkbox v-model="cashierReceipt.MobileEncrypt ">客户手机号加密</el-checkbox>
      </el-form-item>
      <el-form-item label="文案:">
        <el-row>
          <el-col :span="6">
            <el-input v-model="cashierReceipt.WriteTextFirst" size="small" placeholder="请输入内容"></el-input>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="6">
            <el-input v-model="cashierReceipt.WriteTextSecond" size="small" placeholder="请输入内容"></el-input>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item label="纸质尺寸:">
        <el-radio-group v-model="cashierReceipt.PrintWidth">
          <el-radio :label="58">58mm</el-radio>
          <el-radio :label="80">80mm</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="一次打印:">
          <el-radio-group v-model="cashierReceipt.PrintQuantity">
            <el-radio :label="1">一张</el-radio>
            <el-radio :label="2">二张</el-radio>
            <el-radio :label="3">三张</el-radio>
          </el-radio-group>
      </el-form-item>

      <el-form-item label="显示门店地址:">
        <el-radio-group v-model="cashierReceipt.EntityAddress">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>

      <!-- <el-form-item label="显示经手人:">
        <el-radio-group v-model="cashierReceipt.Handler">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item> -->

      <div class="tip marbm_10 font_14 color_333" style="margin-top:0">打印设置</div>
      <el-form-item label="销售信息:">
          <el-checkbox v-model="cashierReceipt.SaleGoodsOriginPrice">商品原价</el-checkbox>
          <el-checkbox v-model="cashierReceipt.SalePromotions">手动改价和卡优惠</el-checkbox>
      </el-form-item>
      <el-form-item label="销售退款:">
          <el-checkbox v-model="cashierReceipt.SaleRefundBuyQuantity">购买数量</el-checkbox>
          <el-checkbox v-model="cashierReceipt.SaleRefundBuyAmount">购买金额</el-checkbox>
          <el-checkbox v-model="cashierReceipt.SaleRefundableAmount">可退金额</el-checkbox>
      </el-form-item>


      <el-form-item label="销售补欠款:">
          <el-checkbox v-model="cashierReceipt.SaleArrearBuyQuantity">购买数量</el-checkbox>
          <el-checkbox v-model="cashierReceipt.SaleArrearBuyAmount">购买金额</el-checkbox>
          <el-checkbox v-model="cashierReceipt.SaleArrearBalanceAmount">剩余欠尾金额</el-checkbox>
      </el-form-item>
      
      <el-form-item label="消耗信息:">
          <el-checkbox v-model="cashierReceipt.TreatGoodsOriginPrice">商品原价</el-checkbox>
          <el-checkbox v-model="cashierReceipt.TreatPromotions">手动改价和卡优惠</el-checkbox>
          <el-checkbox v-model="cashierReceipt.TreatAmount">消耗金额</el-checkbox>
      </el-form-item>
      
      <el-form-item>
        <el-button type="primary" @click="createReceiptConfig" :loading="submitLoading" size="small">保存</el-button>
      </el-form-item>

    </el-form>
  </div>
</template>

<script>
import cashierAPI from "@/api/iBeauty/Order/cashierReceipt";

export default {
 name: 'cashierReceipt',
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      submitLoading:false,
      cashierReceipt:{
        EntityID: "",
        MobileEncrypt: false,
        NameEncrypt: false,
        PrintQuantity: "",
        PrintWidth: 58,
        SaleArrearBuyAmount: false,
        SaleArrearBuyQuantity: false,
        SaleGoodsOriginPrice: false,
        SalePromotions: false,
        SaleRefundBuyAmount: false,
        SaleRefundBuyQuantity: false,
        SaleRefundableAmount: false,
        TreatGoodsOriginPrice: false,
        TreatPromotions: false,
        TreatAmount:false,
        WriteTextFirst: "",
        WriteTextSecond: "",
      },
    }
  },
   /**计算属性  */
  computed: {
  },
  /**  方法集合  */
  methods: {
    /**  获取小票配置信息  */
    getReceiptConfig(){
      var that = this;
      cashierAPI.getReceiptConfigBill().then(res=>{
        if(res.StateCode == 200){
          that.cashierReceipt = res.Data;
        }
        else{
          that.$message.error({
            message: res.Message,
            duration: 2000,
          })
        }
      }).finally(()=>{
        
      })
    },

     /**  保存小票配置信息  */
    createReceiptConfig(){
      var that = this;
      that.submitLoading = true;
      cashierAPI.receiptConfigCreate(that.cashierReceipt).then(res=>{
        if(res.StateCode == 200){
          that.$message.success({
            message: res.Message,
            duration: 2000,
          })
        }
        else{
          that.$message.error({
            message: res.Message,
            duration: 2000,
          })
        }
      }).finally(()=>{
        that.submitLoading = false;
      })
    },
  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {
    let that = this;
    that.getReceiptConfig()
  },
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
}
</script>

<style lang="scss">
.cashierReceipt{

}
</style>
